import { AppScreen } from '../../stackflow/components';
import { NotFoundView } from './NotFoundView';

const NotFoundPage = () => {
  return (
    <AppScreen>
      <NotFoundView />
    </AppScreen>
  );
};

export default NotFoundPage;
